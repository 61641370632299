<template>
	<v-img class="mt-2" contain height="180" :src="event.imgURL" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'EventLogo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			event: 'events/event',
		}),
	},
}
</script>
